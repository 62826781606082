<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Layout from "../../../layouts/main";

import PageHeader from "@/components/page-header";

import store from "@/state/store";
import {apiTermMethods} from "@/state/helpers";
import Swal from 'sweetalert2'
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

/**
 * Editors component
 */
  
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: { Layout, ckeditor: CKEditor.component, PageHeader },
  data() {
    return {
      menuId: 500200,
      menuCode: "terms",
      editor: ClassicEditor,

      uid : "",
      form: [],
      formSubmitted: false,
      statusOptions: [
        { text: '표시', value: 'Active' },
        { text: '사용안함', value: 'Disable' }
      ]
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    this.loadData()
  },
  validations: function() {
    return {
      form: {
        title:{
          required: helpers.withMessage("제목을 입력해 주세요.", required),
        },
        type: {
          required: helpers.withMessage("타입을 입력해 주세요.", required),
        },
        code: {
          required: helpers.withMessage("코드를 입력해 주세요.", required),
        },
        status: {
          required: helpers.withMessage("상태를 선택해 주세요.", required),
        },
        content: {
          required: helpers.withMessage("내용을 입력해 주세요.", required)
        },
      }
    }
  },
  methods: {
    ...apiTermMethods,

    // 데이터 로딩
    loadData() {
      //uid있으면 데이터 불러와서 셋팅
      let { uid } = this.$route.params
      
      if(uid){
        store.dispatch("apiTerms/load", { uid }).then((result) => {
          let data = result.data.data
          
          this.form = data
          this.form.content = data.content.trim().replace(/\r\n/g, "<br />");
        })
      }else{
        this.form.status = "Active"
      }
    },

    // 저장 서버요청
    saveData() {
      store.dispatch('notification/clear')

      // stop here if form is invalid
      this.v$.$touch();

      this.formSubmitted = true

      if (this.v$.form.$invalid) {
        this.formSubmitted = false
      } else {
        let params = {
          "title" : this.form.title,
          "type" : this.form.type,
          "code": this.form.code,
          "status": this.form.status,
          "content": this.form.content,
        }

        if(this.form.uid){
            params.uid = this.form.uid
        }
        
        store.dispatch("apiTerms/store", params).then((result) => {
          this.formSubmitted = false
          Swal.fire(result.message, "", result.success ? "success" : "error");
          // window.location.reload()
          this.$router.push('/setting/terms')
        }, () => {
          this.formSubmitted = false
        });
      }
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
            >
              {{ notification.message }}
            </div>

            <b-form  @submit.prevent="saveData">
                <b-form-group
                    class="mb-3"
                    label="제목"
                    label-for="title"

                    :invalid-feedback="v$.form.title.$error ? v$.form.title.$errors[0].$message : null"
                    :state="!v$.form.title.$error"
                >
                    <b-form-input
                    type="text"
                    v-model="form.title"

                    :state="!v$.form.title.$error"
                    :disabled="formSubmitted"
                    ></b-form-input>
                </b-form-group>

                <div class="row">
                    <b-form-group
                        class="mb-3 col-6"
                        label="타입"
                        label-for="type"

                        :invalid-feedback="v$.form.type.$error ? v$.form.type.$errors[0].$message : null"
                        :state="!v$.form.type.$error"
                    >
                        <b-form-input
                        type="text"
                        v-model="form.type"

                        :state="!v$.form.type.$error"
                        :disabled="formSubmitted"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        class="mb-3 col-6"
                        label="코드"
                        label-for="code"

                        :invalid-feedback="v$.form.code.$error ? v$.form.code.$errors[0].$message : null"
                        :state="!v$.form.code.$error"
                    >
                        <b-form-input
                        type="text"
                        v-model="form.code"

                        :state="!v$.form.code.$error"
                        :disabled="formSubmitted"
                        ></b-form-input>
                    </b-form-group>
                </div>

                <b-form-group class="mb-3" label="상태">
                    <b-form-radio-group
                        v-model="form.status"
                        :options="statusOptions"
                        name="status"
                    >
                    </b-form-radio-group>
                </b-form-group>


              <b-form-group
                  class="mb-3"
                  id="password-group"
                  label="내용"
                  label-for="new_password_confirmation"

                  :invalid-feedback="v$.form.content.$error ? v$.form.content.$errors[0].$message : null"
                  :state="!v$.form.content.$error"
              >
                <ckeditor v-model.lazy="form.content" :editor="editor"
                          :state="!v$.form.content.$error"></ckeditor>
              </b-form-group>
                <div class="row">
                    <router-link :to="{name:'terms'}" class="text-start col-4">
                        <b-button variant="success">목록</b-button>
                    </router-link>
                    <div class="text-center col-4">
                        <b-button variant="primary" type="submit" :disabled="formSubmitted">
                        <span v-if="form.uid">수정</span>
                        <span v-else>저장</span>
                        </b-button>
                    </div>
                </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
